<template>
  <div class="p-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Browse
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h4>
      {{ PageTitle }}
    </h4>
    <hr class="separate-line" />
    <div id="content" class="p-4">
      <el-card
        style="margin:5px 0 30px 0"
        v-for="practice in adaptivePractice.compose_practices"
        :key="practice.id"
      >
        <div class="question-content">
          <router-link
            style="float: right; padding: 3px 0"
            :to="{
              name: 'SatComposePracticeDetailAll',
              query: { id: practice.id }
            }"
          >
            <el-button type="success" size="mini" plain>
              <i class="fas fa-eye" />
            </el-button>
          </router-link>
        </div>
        <div>
          <div class="small" style="font-size: 80%;">
            <h5>
              {{ this.getCompanyExamTitle(practice.exam.title) }}
            </h5>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      adaptivePractice: null
    };
  },
  computed: {
    PageTitle() {
      let title =
        this.adaptivePractice &&
        this.adaptivePractice.exam &&
        this.adaptivePractice.exam.title
          ? this.getCompanyExamTitle(this.adaptivePractice.exam.title) + " - Adaptive"
          : "Adaptive Test";
      return title;
    },
    instant() {
      return instant;
    },
    id() {
      return this.$route.query.id;
    }
  },
  watch: {},

  async mounted() {
    if (this.id && Number(this.id) > 0) {
      this.getAdaptivePracticeDetail();
    }
  },

  methods: {
    async getAdaptivePracticeDetail() {
      const res = await SAT.getAdaptiveEdit(this.id);
      this.adaptivePractice = res.adaptive_practice;
    }
  }
};
</script>

<style scoped>
#content {
  width: 1184.56px;
  margin: 0 auto;
  position: relative;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}
.questions-list {
  height: 600px;
  overflow: auto;
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}
</style>
